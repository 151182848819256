
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
} from "@mui/material";
import "./StoreManagement.css"; 
import add from "../../assets/Walnut/add.svg";
import EditProductModal from "./EditProductModal"; 
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import api from "../../api/tokenCode"; 

const StoreManagement = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await api.get("/admin/getProducts"); 
        console.log(response.data, "test"); 

        const productList = response.data.list || [];

        setProducts(productList); 
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]); 
      }
    };

    fetchProducts(); 
  }, []); 

  const filteredProducts = products.filter((product) => {
    return (
      product.productName &&
      product.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // const handleClearFilters = () => {
  //   setSearchTerm("");
  // };

  // const handleEditProduct = (product) => {
  //   console.log(`Edit product with ID: ${product._id}`); 
  // };

  // const handleDeleteProduct = async (id) => {
  //   try {
  //     await api.delete(`/admin/deleteProduct/${id}`); 
  //     setProducts(products.filter((product) => product._id !== id));
  //     console.log(`Deleted product with ID: ${id}`);
  //   } catch (error) {
  //     console.error(`Error deleting product with ID ${id}:`, error);
  //   }
  // };

  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />
      <Box className="store-padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
          <Table>
            <div className="store-header">
              <h2>Store Management</h2>
              <div style={{ paddingRight: "15px" }}>
                <Button
                  className="add-btn"
                  sx={{
                    color: "white",
                    backgroundColor: "Black",
                    gap: "10px",
                    border: "1px solid #ccc",
                    height: "50px",
                    width: "160px",
                  }}
                  onClick={handleOpenModal} 
                >
                  Add New
                  <img src={add} alt="add" />
                </Button>
              </div>
            </div>
          </Table>
          <hr />

          <Table className="store-table">
            <div style={{ paddingLeft: "20px" }}>
              <div className="store-filters">
                <input
                  name="search"
                  className="store-search-text"
                  placeholder="Search by Product name..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </Table>

          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0 20px",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderCellStyle}>Product Image</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Product Name</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Price</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Stock Status</TableCell>
                {/* <TableCell sx={tableHeaderCellStyle}>Actions</TableCell>  */}
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredProducts.length > 0 ? (
                filteredProducts.map((product) => (
                  <TableRow key={product._id}>
                    {" "}
                    {/* Use _id as the key */}
                    <TableCell sx={tableCellStyle}>
                      <img
                        src={product.productPicter}
                        alt={product.productName}
                        style={{ width: "50px" }}
                      />
                    </TableCell>
                    <TableCell sx={tableCellStyle} className="capitalize-product">
                      {product.productName}
                    </TableCell>
                    <TableCell sx={tableCellStyle}>{product.price}</TableCell>
                    <TableCell sx={tableCellStyle}>
                      <span
                        className={
                          product.stockStatus === "In Stock"
                            ? "in-stock"
                            : "out-of-stock"
                        }
                      >
                        {product.stockStatus}
                      </span>
                    </TableCell>
                    {/* <TableCell sx={tableCellStyle}>
                      <Button onClick={() => handleEditProduct(product)}>Edit</Button>
                      <Button onClick={() => handleDeleteProduct(product._id)}>Delete</Button>
                    </TableCell>  */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      textAlign: "center",
                      padding: "20px",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <EditProductModal open={openModal} handleClose={handleCloseModal} />
      </Box>
    </Box>
  );
};

export default StoreManagement;
