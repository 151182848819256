import React, { useState } from 'react';
import './Login.css'; 
import { useNavigate } from 'react-router-dom'; 
import signupphoto from "../../assets/Walnut/signupphoto.png";
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false); 
  const navigate = useNavigate(); 

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://159.203.177.130/api/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Login failed, please check your credentials');
      }

      const data = await response.json();
      console.log('Login successful:', data);

      localStorage.setItem('authToken', data.token);

      navigate('/member');
    } catch (err) {
      setError(err.message);
      console.error('Error during login:', err);
    }
  };

  return (
    <div className="signup-container">
      
      <div className="signup-image">
        <img src={signupphoto} alt="signup-photo" style={{ height: '80vh' }}  className='login-image'/>
      </div>
      <div className="signup-form">
        <h1>Get Started!</h1>
        <p>Log in to Your Golf Adventure!</p>

        {error && <p className="error">{error}</p>}

        <form onSubmit={handleSubmit}>
          <div className="input-field">
            <input
              type="email"
              name="email"
              placeholder="Enter email address"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-field">
            <input
              type={showPassword ? 'text' : 'password'} 
              name="password"
              placeholder="Enter password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <IconButton
              onClick={() => setShowPassword(!showPassword)} 
              edge="end"
              style={{ position: 'absolute', right: '15px', top: '9px', zIndex: 1 }} 
              className="eye-open"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />} 
            </IconButton>
          </div>

          <div className="options">
            <label>
              <input type="checkbox" /> Remember Me
            </label>
          </div>

          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
