
import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import api from "../../api/tokenCode";
import "./MemberManagement.css";
import JoinDate from "../../assets/Walnut/joindate.svg";
import booking from "../../assets/Walnut/booking.svg";
import Status from "../../assets/Walnut/status.svg";
import plan from "../../assets/Walnut/plan.svg";
import email from "../../assets/Walnut/email.svg";
import Delete from "../../assets/Walnut/delete.svg";
import edit from "../../assets/Walnut/edit.svg";
import upgrade from "../../assets/Walnut/upgrade.svg";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import CloseIcon from "@mui/icons-material/Close"; 

const getInitials = (name) => {
  const names = name.split(" ");
  if (names.length > 1) {
    return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
  } else {
    return names[0].charAt(0).toUpperCase();
  }
};

const ProfileImage = ({ name }) => {
  const initials = getInitials(name);

  return (
    <div
      style={{
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        backgroundColor: "black",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "36px",
        fontWeight: "bold",
      }}
    >
      {initials}
    </div>
  );
};

const MemberManagement = () => {
  const [members, setMembers] = useState([]); 
  const [selectedMember, setSelectedMember] = useState(null);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    plan: "",
    joinDate: "",
  });

  useEffect(() => {
    const fetchSubscribedUsers = async () => {
      try {
        const response = await api.get("/admin/getSubscribedUsers"); 
        const data = response.data.list; 
        setMembers(data);
        console.log("Fetched Members Data:", data);
      } catch (error) {
        console.error("Error fetching subscribed users:", error);
      }
    };

    fetchSubscribedUsers();
  }, []); 

  const filteredMembers = members.filter((member) => {
    return (
      (member.firstName.toLowerCase().includes(filter.search.toLowerCase()) ||
        member.email.toLowerCase().includes(filter.search.toLowerCase())) &&
      (filter.status
        ? member.status.toLowerCase() === filter.status.toLowerCase()
        : true) &&
      (filter.plan
        ? member.SubscriptionPlan.toLowerCase() === filter.plan.toLowerCase()
        : true) &&
      (filter.joinDate ? member.joinDate === filter.joinDate : true)
    );
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  // const handleClearFilters = () => {
  //   setFilter({ search: "", status: "", plan: "", joinDate: "" });
  // };

  // const handleDeleteMember = (id) => {
  //   console.log(`Delete member with ID: ${id}`);
  // };

  const handleEditMember = (e, member) => {
    e.stopPropagation(); 
    setSelectedMember(member); 
  };

  const handleCloseDetails = () => {
    setSelectedMember(null);
  };

  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />
      <Box className="padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
          <Table>
            <div className="header">
              <h2>Members Management</h2>
            </div>
          </Table>
          <hr />
          <Table>
            <div style={{ paddingLeft: "20px" }}>
              <div
                className="filters"
                style={{ marginBottom: "16px", marginTop: "20px" }}
              >
                <input
                  name="search"
                  className="search-textj"
                  placeholder="Search members by name, email or Id"
                  value={filter.search}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
          </Table>

          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0 20px",
            }}
          >
            {/* Table Header */}
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderCellStyle}>Name</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Email Address</TableCell>
                <TableCell sx={tableHeaderCellStyle}>Join Date</TableCell>
                <TableCell sx={tableHeaderCellStyle}>
                  Subscription Plan
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {filteredMembers.length > 0 ? (
                filteredMembers.map((member) => (
                  <TableRow
                    key={member._id}
                    onClick={(e) => handleEditMember(e, member)}
                  >
                    <TableCell sx={tableCellStyle} className="capitalize">
                      {member.firstName}
                    </TableCell>
                    <TableCell sx={tableCellStyle}>{member.email}</TableCell>
                    <TableCell sx={tableCellStyle}>
                      {new Date(member.createdAt)
                        .toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })
                        .replace(/\//g, "-")}
                    </TableCell>
                    <TableCell sx={tableCellStyle} className="capitalize">
                      {member.SubscriptionPlan}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{ textAlign: "center", padding: "20px" ,fontSize:'30px',fontWeight:'bold' }}
                    
                  >
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Selected Member Details */}
        {/* {selectedMember && (
          <div
            className="member-details"
            style={{
              marginTop: "20px",
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              display: "block",
            }}
          >
            <div className="selectmemSection">
              <div className="profile" style={{ display: "flex", alignItems: "center" }}>
                <ProfileImage name={`${selectedMember.firstName}`} />
                <div>
                  <h3>{selectedMember.firstName}</h3>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={email}
                      alt="email"
                      style={{ marginRight: "10px", borderRadius: "50%" }}
                    />
                    <p>{selectedMember.email}</p>
                  </div>
                </div>
              </div>
              <div className="member-meta">
                <div style={{ backgroundColor: "#EEF0F1" }}>
                  <img src={JoinDate} alt="joindate" />
                  <ul className="dash-ul">
                    <li>Join Date:</li>
                    <li>{new Date(selectedMember.createdAt).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }).replace(/\//g, '-')}</li>
                  </ul>
                </div>
                <div style={{ backgroundColor: "#EEF0F1" }}>
                  <img src={plan} alt="plan" />
                  <ul className="dash-ul">
                    <li>Current Plan:</li>
                    <li className="capitalize">{selectedMember.SubscriptionPlan}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )} */}

        {selectedMember && (
          <div
            className="member-details"
            style={{
              marginTop: "20px",
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              display: "block",
              position: "relative", 
            }}
          >
            <CloseIcon
              onClick={handleCloseDetails} 
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer", 
              }}
            />
            <div className="selectmemSection">
              <div
                className="profile"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ProfileImage name={`${selectedMember.firstName}`} />
                <div>
                  <h3 className="capitalize">{selectedMember.firstName}</h3>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={email}
                      alt="email"
                      style={{ marginRight: "10px", borderRadius: "50%" }}
                    />
                    <p>{selectedMember.email}</p>
                  </div>
                </div>
              </div>
              <div className="member-meta">
                <div style={{ backgroundColor: "#EEF0F1" }}>
                  <img src={JoinDate} alt="joindate" />
                  <ul className="dash-ul">
                    <li>Join Date:</li>
                    <li>
                      {new Date(selectedMember.createdAt)
                        .toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })
                        .replace(/\//g, "-")}
                    </li>
                  </ul>
                </div>
                <div style={{ backgroundColor: "#EEF0F1" }}>
                  <img src={plan} alt="plan" />
                  <ul className="dash-ul">
                    <li>Current Plan:</li>
                    <li className="capitalize">
                      {selectedMember.SubscriptionPlan}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default MemberManagement;
