import React from 'react';
import './App.css';
import LeftSidenav from './components/LeftSidenav/LeftSidenav';
import TopNavbar from './components/TopNavbar/TopNavbar';
import SignUp from './components/Login/Login';
import Main from './Routes/Routes';  
import { BrowserRouter as Router } from 'react-router-dom';



function App() {
  return (
    <Router>
      

          <Main />  
        
  </Router>
  );
}

export default App;
