import React, { useState } from "react";
import {
  Button,
  Table,
  TableContainer,
  Paper,
  Box,
} from "@mui/material";
import add from "../../assets/Walnut/add.svg";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import ReactQuill CSS
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import "./NotificationManagement.css";

const NotificationManagement = () => {
  const [emailCategory, setEmailCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState(""); // message is the rich text value
  const [recipient, setRecipient] = useState("AKSHATA SHINDE");

  const handleSend = () => {
    console.log("Notification Sent!");
    console.log("Message Content:", message); // Logging the rich text content
    // Handle sending logic here
  };

  return (
    <Box sx={{ marginLeft: 5}}>
      <LeftSidenav />
      <TopNavbar />
      <Box className="notifiy-padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA",height:'86vh' }}>
          <Table>
            <div className="notify-header">
              <h2>Notification Management</h2>
            </div>
          </Table>
          <hr />

          <Table>
            {/* Search Bar */}
            <div className="search-input">
              <input
                className="notify-search-text"
                placeholder="Search Notifications"
                fullWidth
                style={{ marginRight: "10px" }}
              
              />
              <div style={{ paddingRight: "15px" }}>
                <Button
                  className="add-btn"
                  sx={{
                    color: "white",
                    backgroundColor: "Black",
                    gap: "10px",
                    border: "1px solid #ccc",
                    height: "50px",
                    width: "160px",
                  }}
                >
                  Create New
                  <img src={add} alt="add" />
                </Button>
              </div>
            </div>
          </Table>

          {/* Send Notification Form */}
          <Table>
            <div className="send-header">
              <h3>Send Notification</h3>
            </div>
          </Table>
          <Table>
            <div className="email-category" style={{ padding: "15px" }}>
              <div>
                <input
                  className="category"
                  placeholder="Email category"
                  fullWidth
                />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <input className="category-1" placeholder="From" fullWidth />
                <input className="category-1" placeholder="To" fullWidth />
              </div>
              <input className="category-2"
               placeholder="Subject" 
               fullWidth  
              />
              

              {/* Rich Text Editor */}
              {/* <div className="message-input-container">
                <ReactQuill
                  theme="snow"
                  value={message}
                  onChange={setMessage} // Update the rich text value in state
                  placeholder="Type your message here..."
                  className="quill-text"
                />
                <button onClick={handleSend} className="send-button">
                  Send
                </button>
              </div> */}


<div className="message-input-container">
              <ReactQuill
              className="react-quill"
                value={message} // Bind message state to ReactQuill
                onChange={setMessage} // Update message state on change
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['link', 'image', 'video'],
                    ['clean'] // Remove formatting button
                  ],
                }}
                              />
              <button onClick={handleSend} className="send-button">
                Send
              </button>
            </div>
            </div>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default NotificationManagement;




