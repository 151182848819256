

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LeftSidenav.css";
import walnutLogo from "../../assets/Walnut/walnutlogo.png";

const LeftSidenav = () => {
  const [activeIcon, setActiveIcon] = useState(null); 
  const navigate = useNavigate();

  const handleIconClick = (iconId, route) => {
    setActiveIcon(iconId); 
    navigate(route); 
  };

  return (
    <div className="sidenav">
      <img src={walnutLogo} alt="Logo" className="sidenav-logo" />
      <div className="icon-buttons">

        <div className="button-sections">
          <button
            className={`icon-btn ${activeIcon === "icon2" ? "active" : ""}`}
            onClick={() => handleIconClick("icon2", "/member")}
          >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M7.25 8.5C7.25 7.12109 8.37109 6 9.75 6H21C22.3789 6 23.5 7.12109 23.5 8.5V23.5C23.5 24.8789 22.3789 26 21 26H9.75C8.37109 26 7.25 24.8789 7.25 23.5V8.5ZM11 20.375C11 20.7188 11.2812 21 11.625 21H19.125C19.4688 21 19.75 20.7188 19.75 20.375C19.75 18.6484 18.3516 17.25 16.625 17.25H14.125C12.3984 17.25 11 18.6484 11 20.375ZM15.375 16C16.038 16 16.6739 15.7366 17.1428 15.2678C17.6116 14.7989 17.875 14.163 17.875 13.5C17.875 12.837 17.6116 12.2011 17.1428 11.7322C16.6739 11.2634 16.038 11 15.375 11C14.712 11 14.0761 11.2634 13.6072 11.7322C13.1384 12.2011 12.875 12.837 12.875 13.5C12.875 14.163 13.1384 14.7989 13.6072 15.2678C14.0761 15.7366 14.712 16 15.375 16Z"
                fill={activeIcon === "icon2" ? "white" : "gray"} 
              />
              
             
            </svg>
          </button>
        </div>

        <div className="button-sections">
          <button
            className={`icon-btn ${activeIcon === "icon3" ? "active" : ""}`}
            onClick={() => handleIconClick("icon3", "/scheduling")}
          >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M9.25329 2.6665C9.80796 2.6665 10.2573 3.0985 10.2573 3.63184V5.5785C11.148 5.5625 12.1466 5.5625 13.2706 5.5625H18.624C19.7466 5.5625 20.7453 5.5625 21.636 5.57984V3.63317C21.636 3.09984 22.0853 2.6665 22.64 2.6665C23.1946 2.6665 23.644 3.0985 23.644 3.63184V5.6665C25.5706 5.8145 26.836 6.1785 27.764 7.07317C28.6946 7.9665 29.0733 9.1825 29.2266 11.0358L29.3333 11.9998H2.66663V11.0345C2.82129 9.18117 3.19996 7.96517 4.12929 7.07184C5.05863 6.1785 6.32263 5.81317 8.24929 5.66517V3.63184C8.24929 3.0985 8.69863 2.6665 9.25329 2.6665Z"
                fill={activeIcon === "icon3" ? "white" : "gray"} 
              />
                            <path
                opacity="0.5"
                d="M29.3333 18.6667V16C29.3333 14.8813 29.328 12.8867 29.3107 12H2.67999C2.66266 12.8867 2.66666 14.8813 2.66666 16V18.6667C2.66666 23.6947 2.66666 26.2093 4.22933 27.7707C5.79199 29.332 8.30399 29.3333 13.3333 29.3333H18.6667C23.6933 29.3333 26.208 29.3333 27.7707 27.7707C29.3333 26.208 29.3333 23.696 29.3333 18.6667Z"
                fill={activeIcon === "icon2" ? "gray" : "white"} 
                
              />
            </svg>
          </button>
        </div>

        <div className="button-sections">
          <button
            className={`icon-btn ${activeIcon === "icon4" ? "active" : ""}`}
            onClick={() => handleIconClick("icon4", "/store")}
          >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M5.33333 5.3335H26.6667V8.00016H5.33333V5.3335ZM26.6667 9.3335H5.33333L4 16.0002V18.6668H5.33333V26.6668H18.6667V18.6668H24V26.6668H26.6667V18.6668H28V16.0002L26.6667 9.3335ZM16 24.0002H8V18.6668H16V24.0002ZM6.72 16.0002L7.52 12.0002H24.48L25.28 16.0002H6.72Z"
                fill={activeIcon === "icon4" ? "white" : "gray"} 
              />
            </svg>
          </button>
        </div>

        <div className="button-sections">
          <button
            className={`icon-btn ${activeIcon === "icon1" ? "active" : ""}`}
            onClick={() => handleIconClick("icon1", "/notification")}
          >
            <svg width="32" height="32" viewBox="0 0 24 24">
              <path
                opacity="0.4"
                d="M18.75 2.8125C18.75 1.94922 18.0508 1.25 17.1875 1.25H12.8125C11.9492 1.25 11.25 1.94922 11.25 2.8125V7.1875C11.25 8.05078 11.9492 8.75 12.8125 8.75H17.1875C18.0508 8.75 18.75 8.05078 18.75 7.1875V2.8125ZM8.75 12.8125C8.75 11.9492 8.05078 11.25 7.1875 11.25H2.8125C1.94922 11.25 1.25 11.9492 1.25 12.8125V17.1875C1.25 18.0508 1.94922 18.75 2.8125 18.75H7.1875C8.05078 18.75 8.75 18.0508 8.75 17.1875V12.8125Z"
                fill={activeIcon === "icon1" ? "white" : " gray"} 
              />
                 <path
                d="M8.75 3.125C8.75 2.08984 7.91016 1.25 6.875 1.25H3.125C2.08984 1.25 1.25 2.08984 1.25 3.125V6.875C1.25 7.91016 2.08984 8.75 3.125 8.75H6.875C7.91016 8.75 8.75 7.91016 8.75 6.875V3.125ZM18.75 13.125C18.75 12.0898 17.9102 11.25 16.875 11.25H13.125C12.0898 11.25 11.25 12.0898 11.25 13.125V16.875C11.25 17.9102 12.0898 18.75 13.125 18.75H16.875C17.9102 18.75 18.75 17.9102 18.75 16.875V13.125Z"
                fill={activeIcon === "icon1" ? "white" : " gray"} 
                
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeftSidenav;






