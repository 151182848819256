import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MemberManagement from '../components/MemberManagement/MemberManagement';
import NotificationManagement from '../components/NotificationManagemant/NotificationManagement';
import SchedulingManagement from '../components/SchedulingManagemant/SchedulingManagement';
import StoreManagement from '../components/StoreManagement/StoreManagement';
import SignUp from '../components/Login/Login';



function Main() {
  return (
    <div>
    <Routes>
    <Route path="/" element={<SignUp />} />
      <Route path="/member" element={<MemberManagement />} />
      <Route path="/notification" element={<NotificationManagement />} />
      <Route path="/scheduling" element={<SchedulingManagement />} />
      <Route path="/store" element={<StoreManagement />} />
    </Routes>
    </div>
  );
}

export default Main;
