import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch, Box } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import edit from "../../assets/Walnut/edit.svg";
import Delete from "../../assets/Walnut/delete.svg";
import expotarrow from "../../assets/Walnut/exportarrow.svg";
import plusarrow from "../../assets/Walnut/plusarrow.svg";
import "./SchedulingManagement.css";


const ScheduleManagement = () => {
  const [calendarView, setCalendarView] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    simulatorBay: "",
  });

  const rows = [
    { id: 1, name: "John Doe", bookingDate: "01/12/2023", timeSlot: "10:00-11:00", simulatorBay: "Bay 1", status: "Confirmed" },
    { id: 2, name: "Jane Smith", bookingDate: "02/22/2023", timeSlot: "12:00-13:00", simulatorBay: "Bay 2", status: "Pending" },
    { id: 3, name: "Dev", bookingDate: "19/12/2023", timeSlot: "14:00-15:00", simulatorBay: "Bay 1", status: "Confirmed" },
    { id: 4, name: "Tom Jones", bookingDate: "12/12/2023", timeSlot: "12:00-13:00", simulatorBay: "Bay 3", status: "Canceled" },
    { id: 5, name: "John Doe", bookingDate: "01/12/2023", timeSlot: "14:00-15:00", simulatorBay: "Bay 2", status: "Confirmed" },
  ];

  const localizer = momentLocalizer(moment);

  const handleCalendarViewToggle = (event) => {
    setCalendarView(event.target.checked);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };


  const filteredSchedules = rows.filter((row) => {
    return (
      row.name.toLowerCase().includes(filter.search.toLowerCase()) &&
      (filter.status ? row.status.toLowerCase() === filter.status.toLowerCase() : true) &&
      (filter.simulatorBay ? row.simulatorBay === filter.simulatorBay : true)
    );
  });

  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
  };

  const tableRowStyle = {
    marginBottom: "40px",
    borderRadius: "8px",
    backgroundColor: "#fff",
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />
      <Box className="schedule-padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA"}}>
          <Table>
            <div className="schedule-header">
              <h2>Scheduling Management</h2>
              <div style={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                <span style={{ marginRight: "10px" }}>Calendar View</span>
                <Switch
                  checked={calendarView}
                  onChange={handleCalendarViewToggle}
                  style={{ marginRight: "10px" }}
                />
                {/* <img src={plusarrow} alt="plusarrow" style={{ marginRight: "10px" }} /> */}
                <img src={expotarrow} alt="expotarrow" style={{ marginLeft: "10px", borderRadius: "50%" }} />
              </div>
            </div>
          </Table>
          <hr />
          <Table className="schedule-table">
            <div style={{ paddingLeft: "20px" }}>
              <div className="schedule-filters">
                <input
                  name="search"
                  className="schedule-search-text"
                  placeholder="Search by name"
                  value={filter.search}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: 1 }}
               
                />

                {/* <div className="schedule-filter-three">
                <input
                  name="joinDate"
                  className="schedule-date-text"
                  type="date"
                  value={filter.joinDate}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: 1 }}
                />

                <Select
                  name="simulatorBay"
                  value={filter.simulatorBay}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  displayEmpty
                  sx={{ width: "165px", backgroundColor: "white" }}
                >
                  <MenuItem value="">Simulator Bay</MenuItem>
                  <MenuItem value="Bay 1">Bay 1</MenuItem>
                  <MenuItem value="Bay 2">Bay 2</MenuItem>
                  <MenuItem value="Bay 3">Bay 3</MenuItem>
                </Select>

                <Select
                  name="Time Slot"
                  value={filter.status}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  displayEmpty
                  sx={{ width: "165px", backgroundColor: "white" }}
                >
                  <MenuItem value="">Time Slot</MenuItem>
                  <MenuItem value="Confirmed">Morning</MenuItem>
                  <MenuItem value="Pending">Afternoon</MenuItem>
                  <MenuItem value="Canceled">Evening</MenuItem>
                </Select>
              </div> */}

                {/* <div style={{ whiteSpace: "nowrap" }}>
                <Button
                  sx={{
                    color: "black",
                    textDecoration: "underline",
                    paddingRight: "25px",
                    boxShadow: "none",
                    "&:hover": {
                      textDecoration: "underline",
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={handleClearFilters}
                >
                  Clear All
                </Button>
              </div> */}
              </div>
            </div>
          </Table>
          
          {!calendarView ? (
          <Table sx={{ minWidth: 650, borderCollapse: "separate", borderSpacing: "0 20px" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeaderCellStyle}>Name</TableCell>
              <TableCell sx={tableHeaderCellStyle}>Booking Date</TableCell>
              <TableCell sx={tableHeaderCellStyle}>Time Slot</TableCell>
              <TableCell sx={tableHeaderCellStyle}>Simulator Bay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSchedules.length > 0 ? (
              filteredSchedules.map((row) => (
                <TableRow key={row.id} sx={tableRowStyle}>
                  <TableCell sx={tableCellStyle}>{row.name}</TableCell>
                  <TableCell sx={tableCellStyle}>{row.bookingDate}</TableCell>
                  <TableCell sx={tableCellStyle}>{row.timeSlot}</TableCell>
                  <TableCell sx={tableCellStyle}>{row.simulatorBay}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}                     sx={{ textAlign: "center", padding: "20px" ,fontSize:'30px',fontWeight:'bold' }}
                >
                  No schedules found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        
          ) : (
            <div className="calendar-container">
              <Calendar
                localizer={localizer}
                events={rows.map((row) => ({
                  title: `${row.name} - ${row.simulatorBay}`,
                  start: new Date(row.bookingDate),
                  end: new Date(row.bookingDate),
                }))}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500, margin: "20px" }}
                titleAccessor="title"
                views={["month", "week", "day"]}
                defaultView="month"
              />
            </div>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ScheduleManagement;




